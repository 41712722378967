.dot-flashing {
  display: inline-block;
  position: relative;
  width: 1.5em;
  height: 0.5em;
}

.dot-flashing div {
  position: absolute;
  top: 0;
  width: 0.3em;
  height: 0.3em;
  border-radius: 50%;
  background: currentColor;
  animation: dot-flashing 0.8s infinite alternate;
}

.dot-flashing div:nth-child(1) {
  left: 0em;
  animation-delay: 0s;
}

.dot-flashing div:nth-child(2) {
  left: 0.5em;
  animation-delay: 0.2s;
}

.dot-flashing div:nth-child(3) {
  left: 1em;
  animation-delay: 0.4s;
}

@keyframes dot-flashing {
  0% {
    background: currentColor;
  }
  50%,
  100% {
    background: rgba(0, 0, 0, 0.2);
  }
}

/* Optional: Enhance the ScrollToBottom button */
.scroll-to-bottom-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}