.max-h-52 {
  max-height: 13rem;
  /* Adjust based on your requirement */
}

.transition-all {
  transition: max-height 0.3s ease-in-out;
}

/* .gradient-text {
  background: linear-gradient(180deg, #B1B1C1 0%, rgba(177, 177, 193, 0.00) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

/* .gradient-text {
    white-space: nowrap;
    -webkit-mask-image: linear-gradient(180deg, rgba(0,0,0,1) 90%, rgba(0,0,0,0));
} */

.gradient-text {
  position: relative;
  display: inline-block;
  mask-image: linear-gradient(to top, transparent, transparent 50%, #B1B1C1 100%);
  -webkit-mask-image: linear-gradient(to top, transparent, transparent 50%, #B1B1C1 100%);
}

/* Custom styles for markdown code blocks */
.markdown pre:has(code) {
  background: #00000000; 
  white-space: pre-wrap; 
  word-wrap: break-word; 
  overflow-y: visible; 
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: "TT Hoves", sans-serif;
}