.dot-flashing {
  width: 1.5em;
  height: .5em;
  display: inline-block;
  position: relative;
}

.dot-flashing div {
  background: currentColor;
  border-radius: 50%;
  width: .3em;
  height: .3em;
  animation: .8s infinite alternate dot-flashing;
  position: absolute;
  top: 0;
}

.dot-flashing div:first-child {
  animation-delay: 0s;
  left: 0;
}

.dot-flashing div:nth-child(2) {
  animation-delay: .2s;
  left: .5em;
}

.dot-flashing div:nth-child(3) {
  animation-delay: .4s;
  left: 1em;
}

@keyframes dot-flashing {
  0% {
    background: currentColor;
  }

  50%, 100% {
    background: #0003;
  }
}

.scroll-to-bottom-button:hover {
  background-color: #0000001a;
}

.max-h-52 {
  max-height: 13rem;
}

.transition-all {
  transition: max-height .3s ease-in-out;
}

.gradient-text {
  display: inline-block;
  position: relative;
  -webkit-mask-image: linear-gradient(to top, #0000, #0000 50%, #b1b1c1 100%);
  mask-image: linear-gradient(to top, #0000, #0000 50%, #b1b1c1 100%);
}

.markdown pre:has(code) {
  white-space: pre-wrap;
  word-wrap: break-word;
  background: none;
  font-family: TT Hoves, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  overflow-y: visible;
}

.custom-outline {
  box-sizing: border-box;
  z-index: 10;
  border: 3px solid red !important;
}

.page_select {
  color: #1f2937;
}

.video_info_container {
  z-index: 1002;
  text-align: center;
  width: 100%;
  position: fixed;
  top: 50px;
  left: 0;
}

.video_vision_board {
  z-index: 1001;
  width: calc(100vw - 388px);
  position: absolute;
  top: 12px;
  left: 12px;
}

.video_element {
  border-radius: 12px;
}

.video_test_sound {
  cursor: pointer;
  background-image: url("video_play.954f0fa5.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
}

.video_test_sound:hover {
  opacity: .7;
}

.video_test_sound.on {
  background-image: url("video_pause.1fe4c47c.svg");
}

.video_menu_box {
  cursor: pointer;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  height: 50px;
  margin-right: 8px;
  padding: 13px 0;
}

.video_menu_icon {
  width: 20px;
  height: 20px;
  margin: 0 12px 0 20px;
}

.video_menu_icon.tiny_icon {
  width: 30px;
  height: 30px;
  margin: 0 8px 0 15px;
}

.video_menu_box.selected {
  background-color: #60608b;
}

.video_mirrored {
  transform: scale(-1, 1);
}

.video_info {
  white-space: pre-line;
  text-align: left;
  color: #fff;
  z-index: 2001;
  background-color: #000;
  border-radius: 10px;
  max-width: min(600px, 90vw);
  max-height: 90vh;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
  position: relative;
  top: 24px;
  overflow-y: hidden;
}

.video_toggle_size {
  cursor: pointer;
  background: url("search.d963e1ce.svg");
  width: 22px;
  height: 22px;
}

.video_close_div {
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 14px;
}

.video_info_div {
  z-index: 902;
  color: #fff;
  background-color: #000;
  border-radius: 6px;
  align-items: center;
  padding: 2px 4px;
  display: flex;
  position: absolute;
  top: 6px;
  left: 8px;
}

.video_icon {
  width: 32px;
  height: 32px;
  margin-left: auto;
  margin-right: auto;
}

.video_notes {
  z-index: 2003;
  border-radius: 12px;
  width: 340px;
  height: 600px;
  max-height: calc(100vh - 102px);
  position: fixed;
  bottom: 90px;
  right: 12px;
}

.video_menu_page {
  z-index: 1002;
  color: #fff;
  border-radius: 12px;
  width: 364px;
  position: fixed;
  bottom: 98px;
  right: calc(50vw - 182px);
}

.video_menu_left {
  background-color: #161634;
  width: 267px;
}

.video_menu_right {
  background-color: #161634;
  width: 533px;
}

.video_footer_icon {
  text-align: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 0 10px;
}

.video_footer {
  z-index: 1001;
  width: 100vw;
  height: 90px;
  position: fixed;
  bottom: 0;
}

.test_sound_volume_container {
  width: 100%;
  height: 16px;
  display: flex;
}

.test_sound_volume_box {
  background-color: #5a5a7d;
  border-radius: 3px;
  width: 1.5%;
  height: 16px;
  margin-right: 2.5%;
}

.test_sound_volume_box.active {
  background-color: #94abff;
}

.video_extra_window {
  z-index: 1001;
  background-color: #000024;
  width: 390px;
  height: calc(100vh - 140px);
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (width <= 745px) {
  .video_vision_board {
    width: calc(100vw - 24px);
  }
}

@media only screen and (width <= 800px) {
  .video_menu_page {
    width: calc(100vw - 32px);
    bottom: 90px;
    right: 16px;
  }

  .video_menu_left {
    width: 100vw;
  }

  .video_menu_right {
    background-color: unset;
    width: 100vw;
  }

  .video_footer_icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 6px;
  }

  .video_extra_window {
    z-index: 1001;
    width: 100vw;
  }
}
/*# sourceMappingURL=index.a2778c84.css.map */
